










































import { compare } from '../utils'
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const appStore = namespace('app')
@Component
export default class Profile extends Vue {
  @appStore.State(state => state.transify) private transify: object
  @appStore.State(state => state.profile) private profile: object
  @appStore.State(state => state.gameLogin) private gameLogin: boolean
  @appStore.Action('setHistory') private setHistory!: Function
  private history: object[] = []
  setDate(time: number): string {
    const date = new Date(time * 1000)
    const [day, month, year] = [date.getDate() < 10 ? '0' + date.getDate() : date.getDate(), date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1, date.getFullYear()]
    return `${day}.${month}.${year}`
  }
  getHistory(): void {
    if (!this.gameLogin) return
    this['$http'].get('/history').then((res: any) => {
      this.history = res.data.feedback_history.sort(compare('submit_time', 'des'))
      this.setHistory(this.history)
    })
  }
  mounted(): void {
    this.getHistory()
  }
}
